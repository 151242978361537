import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import coverimg from "../../img/cover-img.jpg";
import { logData } from "../../utils/services";

import { ampRabbitMQPageEvent,ampRabbitMQSuccessPageEvent, getParam } from "../../utils";

export default function Tvodsuccess({
  planType,
  orderData,
  order_id,
  platform_id,
  product_id,
  lang,
  txtData,
}) {
  const coverimg =
    process.env.REACT_APP_ASSETS_URL + "/mobile/images/ticket-bottom.svg";
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  const identity = new URLSearchParams(location).get("identity");
  // console.log("orderData tvod success-mob:", orderData);
  // const platform_id=new URLSearchParams(location).get('platform_id');
  // var callback_url = new URLSearchParams(location).get('callback_url');
  // let history = useHistory();
  // const goToPreviousPath=()=>{
  //     // history.goBack()
  //     if(platform_id==='1'){
  //         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
  //     }
  // }

  //   console.log("orderData", orderData);
  // var ua = navigator.userAgent.toLowerCase();
  // var isAndroid =ua.indexOf("android") > -1
  // var ios = /iphone|ipad/.test(ua);
  // console.log('isAndroid::',isAndroid, 'isIOS::',ios,'ua',ua)
  // console.log('isAndroid::',isAndroid, 'isIOS::',ios,'ua',ua, 'AppVER',window.navigator.appVersion)

  var WATCH_URL = "";
  if (orderData?.platform_id === 1) {
      WATCH_URL = "http://hungama.com/payment/success/"
  } else if (
    orderData.content_details.plan_dname === "Rent" &&
    orderData?.platform_id !== 1
  ) {
    const contentName = orderData?.content_details?.plan_name?.replace(
      /\s+/g,
      "-"
    );
    const content_id = orderData?.content_id;
    var watch_url =
      `${process.env.REACT_APP_HUNGAMA_WEB_URL}/movie/` +
      contentName +
      "/" +
      content_id;
    WATCH_URL = watch_url;
  }
  //   console.log("WATCH URL:", WATCH_URL);

  // }

  useEffect(() => {
    if (isLoadingOnce) {
      let source = "";
      if (orderData?.plan_type === "live_concert") {
        source = "PG Page_Live Event";
      } else {
        source = "PG Page_TVOD";
      }
      /*
      amplitudePageEvent(
        orderData?.identity,
        {
          "Page Type": "PG Page_Success",
          Source: source,
          "Plan Name": orderData?.plan_name,
          "Plan Validity": orderData?.plan_valid,
          "Plan Value": orderData?.plan_amount,
          order_id: orderData?.order_id,
          "Page Language": lang ? lang : "en",
        },
        orderData?.product_id,
        orderData?.platform_id
      );
      */
      /* amplitude with RabbitMQ */
      ampRabbitMQSuccessPageEvent({
        identity: orderData?.identity,
        "Page Type": "PG Page_Success",
        Source: source,
        "Plan Name": orderData?.plan_name,
        "Plan Validity": orderData?.plan_valid,
        "Plan Value": orderData?.plan_amount,
        order_id: orderData?.order_id,
        "Page Language": lang ? lang : "en",
        product_id: orderData?.product_id,
        platform_id: orderData?.platform_id,
        device : "mobile"
      });



      /* Frontend Logs */
      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        order_data: orderData,
        log_time: new Date().toLocaleString().replace(",", ""),
        log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
      };
      logData({ logData: JSON.stringify(lgData) }).then(() => {});
    }

    setLoadingOnce(false);
  }, [
    isLoadingOnce,
    orderData,
    lang,
    couponcode,
    hostname,
    location,
    pathname,
  ]);

  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          {/* <span onClick={goToPreviousPath} className="pay-back"><img src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/back-arrow.svg"} alt="arrow" /></span> */}
          <p>
            {orderData?.content_details?.plan_dname === "live event"
              ? txtData?.pay_mob_tvod_ticket_confirmation_head
              : txtData?.pay_mob_tvod_payment_confirmation_head}
          </p>
          {orderData?.content_details?.plan_dname === "live event" ? (
            <p className="live-event-thankyou">
              {txtData?.pay_mob_tvod_thank_u_for_purchase}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div className="ticket-card">
              <div
                className="ticket-bg"
                style={{ backgroundImage: "url(" + coverimg + ")" }}
              ></div>
              <img
                src={orderData?.content_details?.plan_image_desktop}
                alt="cover img"
                className="ticket-img"
              />
            </div>
            <div className="movie-box">
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/paid-icon.svg"
                }
                alt="paid-icon"
                className="paid-icon"
              />
              <h2 className="movie-title">
                {orderData?.content_details?.plan_name}
              </h2>
              <p className="movie-genre">
                {orderData?.content_details?.plan_des}
              </p>
              {orderData?.content_details?.plan_dname === "live event" ? (
                <p style={{ fontSize: "12px", fontWeight: "300" }}>{`${moment(
                  orderData?.start_date
                ).format("MMMM Do")} at ${moment(orderData?.start_date).format(
                  "h:mm"
                )} to ${moment(orderData?.end_date)
                  .format("h:mm a")
                  .toUpperCase()} `}</p>
              ) : (
                ""
              )}
              {orderData?.payment_id !== "14" && (
                <div className="amount-box">
                  <div
                    style={{
                      float: "left",
                      fontSize: "13px",
                      fontWeight: "300",
                    }}
                  >
                    {orderData?.content_details?.plan_dname === "live event"
                      ? "Total Amount Paid"
                      : "Amount"}{" "}
                  </div>
                  <div className="amount" style={{ float: "right" }}>
                    {orderData?.content_details?.plan_currency_symbol}{" "}
                    {orderData?.txn_amount}
                  </div>
                </div>
              )}
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/ticket-bottom.svg"
                }
                alt="ticketbottom"
                className="ticket-card-border"
              />
            </div>

            <div className="pay-mode-box">
              <p className="p-t1">{txtData?.pay_web_tvod_text2}</p>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.pay_web_tvod_text3}
                </div>
                <div className="order-last-col">{orderData?.order_id}</div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.pay_web_tvod_text4}
                </div>
                <div className="order-last-col">
                  {orderData?.end_date &&
                    orderData?.start_date &&
                    (new Date(orderData?.end_date) -
                      new Date(orderData?.start_date)) /
                      (1000 * 60 * 60 * 24) +
                      " Days"}{" "}
                </div>
              </div>
            </div>

            {orderData?.payment_id === "9" && (
              <p className="info">
                <i>Please close the browser to return to the app.</i>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="footer-action">
        <a href={WATCH_URL} className="w-100 blueprimary_btn">
          <img
            src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/play.svg"}
            alt="play"
            className="button-icon"
          />
          {txtData?.pay_web_tvod_text1}
        </a>
      </div>
    </div>
  );
}
